<template>
  <crud-editor
    ref="editor"
    :is-edit="true"
    :definition="definition"
    :fetch-by-id="fetchById"
    :disabled="hasNoEditPermission"
    :save="saveData"
    :return-to="returnTo"
    :item-type="entity"
    :page-loading="loading"
    :get-dependencies="getDependencies"
    :get-layout="layout"
  />
</template>

<script>
import CrudEditor from '@/components/crud/TabbedEditor';
import formMixin from '../form-mixin';
import { hasEditPermissionForCode } from '@/utils/permission';
import { getEditLayoutDefinition } from '../form';

export default {
  components: {
    CrudEditor
  },
  mixins: [formMixin],
  data() {
    return {
      layout: getEditLayoutDefinition,
      entity: 'MyCouponCode',
      returnTo: 'MyCouponCodes'
    };
  },
  computed: {
    hasNoEditPermission() {
      const currentUsersRestaurantID = this.$store.getters.restaurantId;
      const createdByRestaurantID = this.$store.getters['editor/value']('createdByRestaurantID');
      return (
        !hasEditPermissionForCode('MyCouponCodes', this.$store.getters.permissions) ||
        createdByRestaurantID !== currentUsersRestaurantID
      );
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    }
  },
  methods: {
    fetchById(id) {
      return this.$store
        .dispatch(this.entityStore + '/getItem', { selector: { id } })
        .then(item => this.createFormDataFromItem(this, item));
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    }
  }
};
</script>
